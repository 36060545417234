import AboutImg from "../media/images/about-img.jpg"


function AboutMe() {
  return (
    <>
      <section className="about" id="about">
        <div className="container">
          <div className="about-content">
            <div className="img-side">
              <img src={AboutImg} alt="mee" className="img-side__main-img" />

            </div>
            <div className="text-side">
              <h3>About me</h3>
              <h4>
                A dedicated Network Engineer <br /> based in Saratoga Springs, Utah
                📍
              </h4>
              <p>
              Hi I'm Austin Pack, I am a Network Engineer with over 9 years of IT experience. I enjoy solving problems and finding ways to automate day to day tasks. Beyond my professional pursuits, I enjoy spending time with my family, tinkering with my homelab, riding  motorcycles and climbing.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutMe;
