import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';


function Hero() {


  return (
    <>
      <section id="home" className="hero">
        <div className="container">
          <div className="content">
            <div className="hero-main">
              <div className="hero-text">
                <h1>Austin Pack</h1>
                <h3>Network Engineer</h3>
                <span>
                  <a
                    aria-label="linkedin"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/austin-pack/"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                  
                  <a
                    aria-label="github"
                    rel="noreferrer"
                    target="_blank"
                    href="https://github.com/austinpack"
                  >
                  <FontAwesomeIcon icon={faGithub} />
                  </a>
                </span>
              </div>

              <div className="hero-img"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
