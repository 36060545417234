import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
      <>
        <footer>
          <div className="container">
            <div className="footerc">
              {/* <h3>
                Copyright © {new Date().getFullYear()}. All rights are reserved
              </h3> */}
              <div className="footerc__socials">
                <a
                  aria-label="linkedin"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/austin-pack/"
                >
                  <i className="fa-brands fa-linkedin"><FontAwesomeIcon icon={faLinkedin} /></i>
                </a>
                <a
                  aria-label="github"
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/austinpack"
                >
                  <i className="fa-brands fa-github"><FontAwesomeIcon icon={faGithub} /></i>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
  
  export default Footer;
  