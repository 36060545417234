import { useState } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faBarsStaggered, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../css/styles.css"

function Navbar() {
    const [hamburger, setHamburger] = useState(false);
  
    const hamburgerMenu = () => {
      setHamburger(!hamburger);
    };
  
    const pageUp = () => {
      window.scrollTo({ top: (0, 0), behavior: "smooth" });
    };
  
    const navlinks = [
      {
        name: "Home",
        link: "#home",
      },
      {
        name: "About",
        link: "#about",
      },
      // {
      //   name: "Experience",
      //   link: "#experience",
      // },
      {
        name: "Contact",
        link: "#contact",
      },
    ];
  
    return (
      <>
        <nav>
          <h3 onClick={pageUp} className="logo">
            Austin Pack
          </h3>
          <ul>
            {navlinks.map((item) => (
              <li key={item.name}>
                <a href={item.link}>{item.name}</a>
              </li>
            ))}
            <li onClick={() => hamburgerMenu()}>
              <i className="mobile-menu"><FontAwesomeIcon icon={faBarsStaggered} /></i>
              
            </li>
          </ul>
        </nav>
  
        {/* mobile nav */}
        <div className={`mobile-nav ${hamburger ? "open-menu" : "closed-menu"}`}>
          <span onClick={() => hamburgerMenu()}>
            <i className="fa-solid fa-xmark"><FontAwesomeIcon icon={faXmark} /></i>
          </span>
  
          <ul>
            {navlinks.map((item) => (
              <li key={item.name} onClick={() => hamburgerMenu()}>
                <a href={item.link}>{item.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
  
  export default Navbar;
  