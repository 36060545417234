import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faMap, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

function Contact() {
    return (
      <>
        <section id="contact" className="contact">
          <div className="container">
            <div className="contact__content">
              <div className="contact__title">
                <p>contact</p>
                <h3>Feel free to reach out!</h3>
              </div>
              <div className="contact__icons">
                <div className="contact__icon-box">
                  <span>
                  <a href="https://www.google.com/maps/place/Saratoga+Springs,+UT/@40.357922,-111.8909362,12.75z/data=!4m6!3m5!1s0x874d7ec978bb7a97:0xc5493ac704ae8275!8m2!3d40.3301898!4d-111.9044877!16zL20vMDEwZ3d0?entry=ttu"
                    rel='noreferrer'
                    target='_blank'>
                    <FontAwesomeIcon className='contact__icon'  icon={faMap} />
                    </a>
                  </span>
                  <div className="contact__info">
                    <h3>Location</h3>
                    <a href="https://www.google.com/maps/place/Saratoga+Springs,+UT/@40.357922,-111.8909362,12.75z/data=!4m6!3m5!1s0x874d7ec978bb7a97:0xc5493ac704ae8275!8m2!3d40.3301898!4d-111.9044877!16zL20vMDEwZ3d0?entry=ttu"
                    rel='noreferrer'
                    target='_blank'>
                      Saratoga Springs, Utah</a>
                  </div>
                </div>
  
                <div className="contact__icon-box">
                  <span>
                  <a href="mailto:austinrobertpack@gmail.com"
                  rel='noreferrer'
                  target='_blank'>
                    <FontAwesomeIcon className='contact__icon' icon={faEnvelopeOpenText} />
                  </a>
                  </span>
                  <div className="contact__info">
                    <h3>Mail</h3>
                    <a href="mailto:austinrobertpack@gmail.com"
                    rel='noreferrer'
                    target='_blank'>
                      austinrobertpack@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  
  export default Contact;
  