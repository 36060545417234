import Navbar from "./components/NavBar";
import Hero from "./components/Hero";
import AboutMe from "./components/AboutMe";
// import Experience from "./components/Experience";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

export default function MyApp() {
  return (
    <div>
      <Navbar />
      <Hero />
      <AboutMe />
      {/* <Experience /> */}
      <Contact />
      <Footer />
    </div>
  );
}